<template>
  <div class="admin">
    <div class="sub-wrap">
      <div class="container">
        <header class="admin-header">
          <h1>News</h1>
          <p>
            <router-link to="/admin/articles/new" class="button button-ci button-round">
              <img src="../../../assets/icons/plus.svg" />
              <span>News hinzufügen</span>
            </router-link>
          </p>
        </header>
        <admin-nav></admin-nav>
        <div class="admin-body">
          <div v-if="loading">
            <div class="loading-wrap">
              <div class="loading-spinner"></div>
            </div>
          </div>
          <div v-else>
            <table class="table">
              <thead>
                <tr>
                  <th width="5%"></th>
                  <th width="65%">Titel</th>
                  <th width="20%">Datum</th>
                  <th width="10%"></th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="article in articles" v-bind:key="article.uuid">
                  <td>
                    <span v-if="article.enabled" class="status status-green"></span>
                    <span v-else class="status status-red"></span>
                  </td>
                  <td>{{ article.title }}</td>
                  <td>{{ article.created_at_formatted }} Uhr</td>
                  <td style="text-align: right">
                    <router-link :to="'/admin/articles/' + article.uuid" class="campaign-wrap">
                      <span class="material-icons" style="color: #444">create</span>
                    </router-link>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style></style>

<script>
import axios from "axios";
import AdminNav from "@/components/admin_nav";

export default {
  name: "dashboard",
  components: {
    AdminNav,
  },
  data() {
    return {
      search_phrase: "",
      articles: [],
      loading: true,
    };
  },
  methods: {
    get_data() {
      axios
        .get(process.env.VUE_APP_BASE_API + "/admin/articles", {
          headers: { Authorization: this.$store.getters.getToken },
        })
        .then((response) => {
          this.articles = response.data.articles;
          this.loading = false;
        });
    },
  },
  mounted() {
    this.get_data();
  },
};
</script>
